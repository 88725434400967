<template>
  <div class="screen-container">
    <div class="searchTypeBox">
      <div class="typeItem" @click="typeChang(item.key)" :class="{ typeItemActive: item.key === query.searchType }"
        v-for="item in typeItemList" :key="item.key">
        <i :class="item.icon"></i>
        <div>{{ item.value }}</div>
      </div>
    </div>
    <div class="box1">
      <div class="title">院校名称：</div>
      <el-select style="width: 80%" v-model="query.SchoolIdList" multiple filterable remote :reserve-keyword="false"
        placeholder="请输入关键词" :remote-method="querySearchSchool" :loading="selectloading">
        <el-option v-for="item in querySearchSchoolList" :key="item.ID" :label="item.SchoolName" :value="item.ID">
        </el-option>
      </el-select>
    </div>
    <div class="content">
      <div class="content-title">院校地区：</div>
      <div class="no-limit" :class="{ activate: query.SchoolProvinceIdList.length === 0 }"
        @click="selectItem('province')">
        不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.SchoolProvinceIdList" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.ProvinceList" :label="item.ID" :key="index">
          {{ item.Name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="content">
      <div class="content-title">院校类别：</div>
      <div class="no-limit" :class="{ activate: query.SchoolCategoryIdList.length === 0 }"
        @click="selectItem('category')">
        不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.SchoolCategoryIdList" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.SchoolCategoryList" :label="item.ID" :key="index">
          {{ item.CategoryName }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="content">
      <div class="content-title">办学性质：</div>
      <div class="no-limit" :class="{ activate: query.SchoolNature === '' }" @click="selectItem('nature')">
        不限
      </div>
      <el-radio-group class="content-item" v-model="query.SchoolNature" size="mini">
        <el-radio-button v-for="(item, index) in mainData.SchoolNatureList" :label="item.Value" :key="index">
          {{ item.Name }}
        </el-radio-button>
      </el-radio-group>
    </div>

    <div class="content">
      <div class="content-title">特别属性：</div>
      <div class="no-limit" :class="{ activate: query.SchoolTagList.length === 0 }" @click="selectItem('tag')">
        不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.SchoolTagList" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.SchoolTagList" :label="item.Value" :key="index">
          {{ item.Name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="box1">
      <div class="title">录取批次：</div>
      <el-select style="width: 50%" v-model="query.BatchIdList" multiple placeholder="请选择">
        <el-option v-for="item in querySearchBatchList" :key="item.BatchId" :label="item.BatchName" :value="item.BatchId">
        </el-option>
      </el-select>
    </div>
    <div class="submit">
      <div class="submit-box">
        <el-button @mouseenter.native="buttonText = '开通会员'" @mouseleave.native="buttonText = '确认筛选'" @click="submitScreen"
          :type="authorization ? 'primary' : (buttonText == '开通会员' ? 'danger' : 'primary')" plain size="small">{{
            authorization ? '确认筛选' : buttonText }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from "vuex";
import { getStore } from '@/utils/utils'
// import { BaseCondition } from "@/api/common";
// import { SchoolListBySchoolName } from "@/api/college";
// import {
//   LineSchoolScoreBatchList,
//   LineSpecialtyScoreBatchList,
// } from "@/api/enrollData";
import {areaData} from '@/utils/data';
export default {
  name: "ConditionScreen",
  props: {},
  data() {
    return {
      mainData: {},
      schoolName: "",
      // screenButtonLoading: false,
      buttonText: "确认筛选",
      query: {
        SchoolProvinceIdList: [],
        SchoolCategoryIdList: [],
        SchoolIdList: [],
        BatchIdList: [],
        SchoolNature: "",
        SchoolTagList: [],
        searchType: 0,
        //power
        hasZNK: this.$store.state.user.hasZNK,
      },
      querySearchSchoolList: [],
      querySearchBatchList: [],
      typeItemList: [
        {
          value: "院校录取数据",
          key: 0,
          icon: "el-icon-school",
        },
        {
          value: "专业录取数据",
          key: 1,
          icon: "el-icon-tickets",
        },
      ],
      selectloading: false,

      authorization: true
    };
  },
  computed: {
    ...mapGetters(["token", "SubjectType"]),
  },
  mounted() {
    this.initialize();

    // 权限
    this.$store.dispatch('user/allowMember', ['DataQuery']).then(flag => {
      this.authorization = flag
    })
  },
  methods: {
    typeChang(key) {
      this.query.searchType = key;
      this.query.BatchIdList = [];
      this.getquerySearchBatchList();
      this.$emit("screenParameters", this.query, (_) => {
        // this.screenButtonLoading = false
      });
      // this.$globalEventBus.$emit('getEnrollDataListData',this.query.searchType);
    },
    //提交筛选参数
    submitScreen() {
      if (this.authorization) {
        this.schoolName = ""
        this.$emit('screenParameters', this.query, _ => {
          // this.screenButtonLoading = false
        })
      } else {
        this.$router.push('/openvip')
      }
    },
    //选择不限
    selectItem(type) {
      switch (type) {
        case "province":
          this.query.SchoolProvinceIdList = [];
          break;
        case "category":
          this.query.SchoolCategoryIdList = [];
          break;
        case "tag":
          this.query.SchoolTagList = [];
          break;
        case "nature":
          this.query.SchoolNature = "";
          break;
      }
    },
    //select the school
    selectSchool(item) {
      this.query.SchoolCategoryIdList = [];
      this.query.SchoolProvinceIdList = [];
      this.query.SchoolTagList = [];
      this.query.SchoolNature = "";
      this.$emit("searchSchool", item.ID);
    },
    //search school
    querySearchSchool(querySchoolName) {
      this.selectloading = true;
      let tempQuery = {
        SchoolName: querySchoolName,
        PageIndex: 1,
        PageSize: 99999,
      };
      API.School.SchoolListBySchoolName(tempQuery).then((res) => {
        this.querySearchSchoolList = res.data.Data;
        this.selectloading = false;
      });
    },
    //初始化数据
    initialize() {
      this.getScreenList();
      this.getquerySearchBatchList();
    },
    getquerySearchBatchList() {
      this.$getStorage("enrollDataInfo").then((res) => {
        let temp = getStore('OrgProvince')
        if(!temp) {
          this.$message.error('未获取到省份信息！')
          return
        }
        const data = {
          ProvinceId: areaData[JSON.parse(temp).ProvinceId],
          Year: res.Year,
          SubjectType: Number(res.SubjectType),
          SelectSubject: res.SelectSubject,
        };
        this.query.BatchIdList = [];
        if (this.query.searchType === 1) {
          API.Public.LineSpecialtyScoreBatchList(data).then((res) => {
            this.querySearchBatchList = res.data;
          });
        } else {
          API.Public.LineSchoolScoreBatchList(data).then((res) => {
            this.querySearchBatchList = res.data;
          });
        }
      });
    },
    async getScreenList() {
      this.mainData = (await API.School.BaseCondition()).data;
    },
  },
};
</script>

<style scoped lang="less">
// @import "~@/styles/base.scss";

.screen-container {

  padding: 2rem;
  border: 1px solid var(--color-grey);
  background-color: #fff;
  position: relative;

  .box1 {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .title {
      margin-right: 2rem;
    }

    .search-input {
      width: 504px;
    }
  }

  .content {
    margin-bottom: 1rem;
    display: flex;

    &-title {
      width: 6rem;
      font-size: var(--text-font-size);
      margin-right: 1rem;
    }

    .no-limit {
      box-sizing: border-box;
      width: 4.5rem;
      height: 1.5rem;
      line-height: 1;
      margin-right: 1rem;
      padding: 0.3rem 1.2rem;
      font-size: 12px;
      border: 1px solid var(--color-grey);
      color: #606266;
      cursor: pointer;
      border-radius: 1rem;
    }

    .activate {
      color: #ffffff;
      background-color: rgba(var(--themecolor),0.75);
      /*border: 1px solid transparent;*/
    }

    &-item {
      width: calc(100% - 10rem);

      ::v-deep .el-checkbox-button {
        margin: 0 1rem 0.5rem 0;

        .el-checkbox-button__inner {
          padding: 0.3rem 1.2rem;
          border: 1px solid var(--color-grey);
          border-radius: 1rem;
        }
      }

      ::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
        box-shadow: none;
      }

      ::v-deep .el-radio-button {
        margin: 0 1rem 0.5rem 0;

        .el-radio-button__inner {
          padding: 0.3rem 1.2rem;
          border: 1px solid var(--color-grey);
          border-radius: 1rem;
        }

        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
          box-shadow: none;
        }
      }
    }
  }

  .submit {
    text-align: center;

    &-button {
      width: 6rem;
    }
  }
}

.searchTypeBox {
  position: absolute;
  left: -96px;
  top: 0;

  .typeItem {
    width: 75px;
    // height: 80px;
    padding: 10px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 1.5;
    background: #fff;
    text-align: center;

    i {
      font-size: 30px;
    }
  }

  .typeItemActive {
    background: rgba(var(--themecolor),0.75);
    color: #fff;
  }
}
</style>
<style lang="less">
.screen-container .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.screen-container .el-range-editor.is-active, .screen-container .el-range-editor.is-active:hover, .screen-container .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),1);
}
.el-checkbox-button.is-checked .el-checkbox-button__inner{
  background-color: rgba(var(--themecolor),0.75);
}
.el-checkbox-button__inner:hover,.el-radio-button__inner:hover{
  color: rgba(var(--themecolor),1);
}
.el-button--primary.is-plain{
  border-color: rgba(var(--themecolor),0.75);
  color: rgba(var(--themecolor),1);
}
.el-button--primary.is-plain:focus, .el-button--primary.is-plain:hover{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
</style>